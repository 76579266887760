
.library-image{cursor:pointer
}
.library-image:hover{opacity:.5
}
.box{display:flex;justify-content:space-between;align-items:center;width:400px;height:40px;border:2px solid #efefef;padding:0 10px;border-radius:4px;margin:20px 0
}
.box p{overflow:hidden;white-space:nowrap;margin:10px
}
.box button{padding:0;background-color:transparent;border:none;cursor:pointer
}
.box button:focus{outline:none
}
.box button img{width:25px
}